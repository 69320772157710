
var form = document.querySelector(".form");

form.addEventListener("freeform-stripe-appearance", function(event) {
    event.elementOptions.appearance = {
        ...event.elementOptions.appearance,
        variables: {
            // colorPrimary: "",
            fontFamily: "acumin-pro, Helvetica, Arial, sans-serif",
            fontSizeBase: "22px",
            // spacingUnit: "",
            // tabSpacing: "",
            // gridColumnSpacing: "",
            // gridRowSpacing: "",
            colorText: "rgb(29, 29, 29)",
            // colorBackground: "",
            // colorDanger: "",
            // borderRadius: "",
        },
        rules: {
            '.Input': {
                fontSize: "22px",
                lineHeight: "32px",
                border: "1px solid #008279",
                padding: "8px 12px",
                borderRadius: "0 none",
                boxShadow: "inset 0 4px 0 0 rgba(0,68,79,.1)",
            },
            '.Tab, .Input': {},
            '.Tab': {},
            '.Tab:focus, .Input:focus': {},
            '.Label': {
                fontSize: "22px",
                lineHeight: "32px",
                fontWeight: "700",
                margin: "0 0 8px",
            },
        },
    }
});